<template>
  <v-container>
    <v-row class="justify-center text-center">
      <v-col cols="12">
        <h2 class="font-weight-bold mt-4 mb-3">Revisión de formulario</h2>

        <!-- Botón que permite volver al listado de formularios. Dependiendo de si el usuario provenga de
        una lista u otra, se permitirá volver a una página o la otra. -->
        <v-btn
          small
          class="mb-10"
          color="primary"
          @click="$router.push({ path: '/revision-sensorial' })"
          v-if="$route.params.revision == 0"
        >
          <v-icon small class="mr-1">mdi-arrow-left-bold-circle</v-icon>
          Atrás
        </v-btn>

        <v-btn
          small
          class="mb-10"
          color="primary"
          @click="$router.push({ path: '/consultar-sensorial' })"
          v-else
        >
          <v-icon small class="mr-1">mdi-arrow-left-bold-circle</v-icon>
          Atrás
        </v-btn>

        <!-- Checkbox y botón que le da el visto bueno al formulario y lo aprueba, 
        en caso de que el usuario provenga de los formularios en revisión. -->
        <v-row class=" mb-2 justify-end" v-if="$route.params.revision == 0">
          <v-col cols="12" sm="5" md="3" lg="3" class=" d-flex justify-center justify-sm-end justify-md-end justify-lg-end " >
            <v-checkbox
            
            class="mt-n1"
              hide-details
              true-value="1"
              false-value="2"
              label="¿Aprueba este formulario?"
              v-model="aprobacionFormulario.aprobado"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="12" sm="3" md="2" lg="1" class="d-flex justify-center justify-sm-start justify-md-start justify-lg-start ">
            <v-btn
              class="ml-3"
              color="success"
              :loading="guardando_cambios"
              :disabled="aprobacionFormulario.aprobado == '2'"
              small
              @click="sendApprovement()"
            >
              <v-icon small class="mr-1">mdi-content-save</v-icon>
              Guardar
            </v-btn>
          </v-col>
        </v-row>

        <!-- Campos de texto (uno disponible y otro bloqueado), que permite ver o escribir un comentario
        bien sea del ingeniero o del operario. -->
        <v-row
          class="justify-center d-flex mt-3 mb-2"
          v-if="$route.params.revision == 0"
        >
          <v-col cols="12" sm="6" md="6" lg="6" class="mb-n8">
            <v-textarea
              filled
              rows="3"
              auto-grow
              clearable
              label="Comentarios del ingeniero"
              v-model="aprobacionFormulario.comentarios_ingeniero"
            ></v-textarea>
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="6">
            <v-card elevation="0" color="grey lighten-3" height="98px">
              <v-card-subtitle class="text-left"
                >Comentarios del operario:</v-card-subtitle
              >
              <v-card-text class="text-left" style="padding-top: 0px">{{
                formulario[0].comentario_operario
              }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- Tabla donde se muestra todos los datos del formulario -->
        <v-data-table
          :headers="headers"
          :items="datosTabla"
          class="text-center"
          elevation="0"
        >
          <template v-slot:[`item.revisado`]="{ item }">
            <v-icon v-if="item.revisado == 1" color="success">mdi-check</v-icon>

            <v-icon v-else-if="item.revisado == 0" color="error"
              >mdi-close</v-icon
            >
          </template>
          <template v-slot:[`item.no_aplica`]="{ item }">
            <v-icon v-if="item.no_aplica == 1" color="success">mdi-check</v-icon>

            <v-icon v-else-if="item.no_aplica == 0" color="error"
              >mdi-close</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiForm from "../lista_preguntas/services/ListaFormsDataService.js";
import apiLista from "../lista_preguntas/services/ListaDataService.js";

export default {
  data() {
    return {
      guardando_cambios: false,
      formulario: [], //Datos del formulario a revisar
      items: [], //Listado de items
      metodos: [], //Listado de métodos
      datosTabla: [], //Datos de la tabla a visualizar

      //Encabezados de la tabla
      headers: [
        {
          text: "Ítem",
          align: "start",
          sortable: false,
          filterable: false,
          value: "item",
        },

        {
          text: "Método",
          sortable: false,
          filterable: false,
          value: "metodo",
        },

        {
          text: "Revisado",
          sortable: false,
          filterable: false,
          value: "revisado",
        },
        {
          text: "No Aplica",
          sortable: false,
          filterable: false,
          value: "no_aplica",
        },
      ],

      //Objeto que almacenará todos los datos relacionados con la revisión del formulario.
      aprobacionFormulario: {
        ingeniero: "prueba ingeniero",
        fecha_revision: this.fechaActual(),
        aprobado: "2",
        id_formulario: this.$route.params.id,
        comentarios_ingeniero: "",
      },
    };
  },

  async mounted() {
    this.$store.state.stateLoad = true;
    //Muestra el formulario anteriormente seleccionado
    await apiForm.showForm(this.$route.params.id).then(async (response) => {
      this.formulario = response.data;

      //Consigue items y métodos, necesarios para el filtro
      await apiLista.getItems().then((response) => {
        this.items = response.data;
      });

      await apiLista.getMetodos().then((response) => {
        this.metodos = response.data;

        //Recorre por cada formulario, ítem y método y crea un objeto totalmente nuevo el cual va a ser
        //la base para la tabla para mayor legibilidad a la hora de mostrarlo.
        this.formulario.forEach((form) => {
          this.items.forEach((item) => {
            if (form.id_item == item.id_item) {
              this.metodos.forEach((metodo) => {
                if (form.id_metodo == metodo.id_metodo) {
                  this.datosTabla.push({
                    item: item.item,
                    metodo: metodo.metodo,
                    revisado: form.revisado,
                    no_aplica:form.no_aplica,
                  });
                }
              });
            }
          });
        });
      });
    });

    this.$store.state.stateLoad = false;
  },

  methods: {
    //Consigue la fecha y hora actual de la siguiente forma:
    fechaActual() {
      var today = new Date(); // Se instancia un objeto date del cual se extrae la fecha actual

      // Se crea un string con base a la fecha de hoy y se consigue el año, mes y día actuales
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      // Para la hora se usa el mismo objeto date y se le extraen las horas, minutos y segundos
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      // Junta todo lo procesado anteriormente
      var dateTime = date + " " + time;

      // Devuelve la fecha y hora en formato y-m-d hh:mm:ss
      return dateTime;
    },

    /* Método que envía la aprobación o desaprobación del formulario */
    async sendApprovement() {
      this.guardando_cambios = true;
      await apiForm
        .sendApprove(this.aprobacionFormulario) //Envía la aprobación en conjunto con los datos necesarios
        .then(() => {
          this.aprobacionFormulario.ingeniero = "";
          this.aprobacionFormulario.id_formulario = "";
          this.aprobacionFormulario.fecha_revision = "";
          this.aprobacionFormulario.aprobado = "2";

          this.$router.push({ path: "/revision-sensorial" }); //Se devuelve a la lista de formularios no revisados
        })
        .catch((error) => {
          console.log(error);
        });
      this.guardando_cambios = false;
    },
  },
};
</script>

<style>
.v-data-table__wrapper {
  background-color: rgba(236, 236, 236, 0.8);
  border-radius: 10px;
}

td {
  border-bottom: 1px solid #adadad;
}

tr {
  background-color: transparent !important;
}

.v-data-table__mobile-table-row {
  margin: 20px;
  border: 1px solid #adadad;
  border-radius: 10px;
}

.v-data-table__mobile-row:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-label {
  font-size: 13px;
}

.v-input--selection-controls {
  margin-top: 0px;
}

.v-card__subtitle {
  font-size: 0.775rem;
  color: rgba(0, 0, 0, 0.6);
  padding-top: 10px;
}

.v-card__text {
  font-weight: bold;
}

.v-sheet.v-card {
  border-bottom: 1px solid #000000;
}
</style>
