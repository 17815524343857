import api from '../../general/axiosInstance.js'

export default {

    showNotRevisedForms() {
        return api.get("/showNotRevisedForms")
    },

    showForm(id) {
        return api.get(`/showForm/${id}`)
    },

    sendApprove(data) {
        return api.post("/approveForm", data)
    },

    showAllForms(params) {
        return api.post("/showAllForms",params)
    },

    restoreForm(id) {
        return api.put("/restoreForm", {id_formulario: id})
    },

    datesFilter(datos) {
        return api.post("/filterFormsPerDate", datos)
    }
}