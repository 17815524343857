/* Instancia de Axios que nos permitirá acceder a las rutas de la API que están dentro del backend.
   La URL base depende de lo que esté en el .env, por lo cual se deja un .env.example dentro del proyecto
   para que se pueda utilizar este mismo backend en otra url, sea de desarrollo o de producción. */

import axios from 'axios'

const api = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        "Content-type": "application/json"
    }
});

export default api;