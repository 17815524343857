import api from '../../general/axiosInstance.js'

export default {

    getCategories() {
        return api.get('/getAllCategories')
    },

    getItems() {
        return api.get('/getAllItems')
    },

    getMetodos() {
        return api.get('/getAllMethods')
    },

    getTiposVehiculo() {
        return api.get('/getTiposVehiculo')
    },

    filtroMoto() {
        return api.get('/filtroMoto')
    },

    filtroCarro() {
        return api.get('/filtroCarro');
    },

    filtroCategoria(id) {
        return api.post('/filtroCategoria', {id: id})
    },

    filtroItem(id) {
        return api.post('/filtroItem', {id: id})
    },

    crearCategoria(categoria, tipo_vehiculo) {
        return api.post('/crearCategoria', {categoria: categoria, tipo_vehiculo: tipo_vehiculo})
    },

    crearItem(item, codigo, categoria) {
        return api.post('/crearItem', {item: item, codigo: codigo, categoria: categoria})
    },

    crearMetodo(metodo, item, categoria, frecuentemente_no_aplica) {
        return api.post('/crearMetodo', {metodo: metodo, item: item, categoria: categoria , frecuentemente_no_aplica:frecuentemente_no_aplica})
    },

    editarMetodo(metodo, item, categoria, id, frecuentemente_no_aplica) {
        return api.put('/editarMetodo', {metodo: metodo, item: item, id_metodo: id, categoria: categoria, frecuentemente_no_aplica:frecuentemente_no_aplica})
    },

    quitarMetodo(id) {
        return api.put('/quitarMetodo', {id: id})
    },

    guardarCambiosConfigs(configs){
        return api.post('/guardar-config-sensorial', configs)
    }
}